import {
  Address,
  FoServiceLevel,
  DateTimePeriod as IDateTimePeriod,
  ItemSetServices,
  TransportRequestDelivery,
  TransportRequestPickup,
} from "@brenger/api-client";
import cn from "classnames";
import React from "react";

import { Spacer } from "@brenger/react";
import { getAbbrvForAdminArea } from "@brenger/utils";
import { CheckmarkList, ColumnContainer, StopPills, TimePeriod } from "../../components";
import { useFormatDate, useTranslationContext, useTransportContext } from "../../hooks";
import { getShowAppointmentDetails } from "../../utils/getShowAppointmentDetails";
import { ContactType } from "../../utils";

interface StopProps {
  stop: TransportRequestPickup | TransportRequestDelivery;
  stopType: "pickup" | "delivery";
  dtps: IDateTimePeriod[];
  services: ItemSetServices[];
  isBusiness: boolean;
  isMarktplaats: boolean;
  isFlexibleDates: boolean;
}

const AppointmentStopDetails: React.FC<StopProps> = ({
  stop,
  stopType,
  dtps,
  services,
  isBusiness,
  isMarktplaats,
  isFlexibleDates,
}) => {
  const { t } = useTranslationContext();
  const formatDate = useFormatDate("weekday-month-long");
  const formatDateShort = useFormatDate("day-month-short");
  const a = stop.address as Address | undefined;
  const address = [a?.line1, a?.line2].filter(Boolean).join(", ");
  const postalPlusLocality = [
    a?.postal_code,
    a?.locality,
    a?.administrative_area ? getAbbrvForAdminArea(a.administrative_area) : undefined,
    a?.country_code,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div id={`${stopType}-appointment-details`}>
      <h6>{t((d) => d.appointment_details[stopType])}</h6>
      <div className={cn("pt-2")}>
        {!isFlexibleDates &&
          dtps.map((dtp, i) => {
            return (
              <div key={i} className={cn("flex", "gap-x-3")}>
                <div className={cn("first-letter:uppercase")}>{formatDate(dtp.start)}</div>
                <TimePeriod dtp={dtp} />
              </div>
            );
          })}
        {isFlexibleDates && (
          <div className={cn("flex", "gap-x-3")}>
            {t((d, template) =>
              template(d.flex_dates.display_date_range, {
                first_date: formatDateShort(dtps[0].start),
                last_date: formatDateShort(dtps[dtps.length - 1].start),
              })
            )}
            <TimePeriod dtp={dtps[0]} />
          </div>
        )}
        {typeof stop.service_duration === "number" && (
          <i className={cn("block", "pb-2")}>
            {(stop.service_duration / 60).toFixed(0)} min. {t((d) => d.service_time)}
          </i>
        )}
        <div className={cn("text-gray-600")}>
          <div>{address}</div>
          <div>{postalPlusLocality}</div>
          {stop.details.instructions && <div>{stop.details.instructions}</div>}
        </div>
        <Spacer h={2} />
        {/* For Marktplaats orders we render very specific pills, and next to the stop details (instead of underneath) */}
        {!isMarktplaats && <StopPills details={stop.details} services={services} isBusiness={isBusiness} />}
      </div>
    </div>
  );
};

interface ServiceProps {
  stop: TransportRequestPickup | TransportRequestDelivery;
  contactType?: ContactType;
  services: ItemSetServices[];
  serviceLevel: FoServiceLevel;
}

const ServiceDetails: React.FC<ServiceProps> = ({ stop, contactType, services, serviceLevel }) => {
  const { t } = useTranslationContext();
  let level: FoServiceLevel = serviceLevel;
  let text1 = t((d) => d.fo.service[level].text_1);
  let text2 = t((d) => d.fo.service[level].text_2);
  // NOTE: FIXME: Hardcode service level alert !!111!! much risky so shame
  // We don't allow the seller (= pickup) to select a service level, so we default to lowest: farfetchd
  if (contactType === "pickup") {
    level = "farfetchd";
    text1 = t((d) => d.fo.schedule_seller.summary.service_text_1);
    text2 = t((d) => d.fo.schedule_seller.summary.service_text_2);
  }

  return (
    <div>
      <h6>{t((d) => d.fo.payment_flow.service.label)}</h6>
      <div className="pb-2">{t((d) => d.fo.service[level].name)}</div>
      <CheckmarkList items={[text1, text2]} />
      <Spacer h={4} />
      <StopPills details={stop.details} services={services} isBusiness={false} serviceLevel={level} />
    </div>
  );
};

export const AppointmentDetails: React.FC = () => {
  const ctx = useTransportContext();

  const { pickup, delivery } = ctx;
  const services = ctx.tr?.item_sets.flatMap((is) => is.services) || [];
  const isBusiness = ctx.tr?.source_flow === "Business";
  const isMarktplaats = ctx.tr?.source_flow === "Marktplaats";

  if (!pickup || !delivery || !ctx.tr?.source_flow) return null;

  const { showPickup, showDelivery, showService } = getShowAppointmentDetails({
    type: ctx.contactType,
    source: ctx.tr.source_flow,
  });

  const serviceLevel = ctx.tr.internal_attributes.fo_selected_service?.service_level_name;
  return (
    <>
      {showPickup && (
        <ColumnContainer withDivider={showService || showDelivery} className={"xl:min-w-[22rem]"}>
          <AppointmentStopDetails
            stop={pickup}
            stopType={"pickup"}
            dtps={pickup.available_datetime_periods}
            services={services}
            isBusiness={isBusiness}
            isMarktplaats={isMarktplaats}
            isFlexibleDates={ctx.isFlexibleDates}
          />
        </ColumnContainer>
      )}
      {showDelivery && (
        <ColumnContainer withDivider={showService} className={"xl:min-w-[22rem]"}>
          <AppointmentStopDetails
            stop={delivery}
            stopType={"delivery"}
            dtps={delivery.available_datetime_periods}
            services={services}
            isBusiness={isBusiness}
            isMarktplaats={isMarktplaats}
            isFlexibleDates={ctx.isFlexibleDates}
          />
        </ColumnContainer>
      )}
      {showService && serviceLevel && (
        <ColumnContainer withDivider={false} className={"xl:min-w-[22rem]"}>
          <ServiceDetails
            contactType={ctx.contactType}
            stop={showDelivery ? delivery : pickup}
            services={services}
            serviceLevel={serviceLevel}
          />
        </ColumnContainer>
      )}
    </>
  );
};
