import { getIdFromIri } from "@brenger/utils";
import { createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Redirect } from "react-router-dom";

import {
  Contact,
  CustomerPotentialAction,
  DeliveryPotentialAction,
  GeoDateTimePeriod,
  LiveTrackingStatusForTransportRequest,
  Locale,
  PickupPotentialAction,
  PotentialActionItem,
  TransportJobAccountLink,
  TransportRequest,
  TransportRequestDelivery,
  TransportRequestOpenForUpsells,
  TransportRequestPickup,
} from "@brenger/api-client";
import {
  CacheKey,
  ContactType,
  Routes,
  Status,
  coreClient,
  createAccessControl,
  getStatus,
  getTypedStopsFromTr,
  isUUID,
  localeNormalizer,
  logger,
  routePlannerClient,
} from "../utils";

import { isSameDay } from "date-fns";
import { useFreshChat, useRootParams, useTranslationContext } from ".";
import { track } from "../utils/eventTracking";

// NOTE: these perms are here at top-level of context in order to prevent
// over-fetching data. No use in, for example, fetching upsells or ETAs
// when the irrelevant contact type or TJ is already done.
const getPerms = createAccessControl({
  pickup: {
    // NOTE: The status switches to 2 when a TJAL exists. At this point, start checking for ETAs.
    canRetrievePickupEta: [2, 3],
    canRetrieveDeliveryEta: undefined,
    canRetrieveOpenForUpsells: [1, 2, 3],
    canRetrieveLiveTrackingStatus: [3],
    canPollQueries: [1, 2, 3],
  },
  delivery: {
    canRetrievePickupEta: undefined,
    canRetrieveDeliveryEta: [2, 3, 4],
    canRetrieveOpenForUpsells: [1, 2, 3, 4],
    canRetrieveLiveTrackingStatus: [3, 4],
    canPollQueries: [1, 2, 3, 4],
  },
  customer: {
    canRetrievePickupEta: [2, 3, 4],
    canRetrieveDeliveryEta: [2, 3, 4],
    canRetrieveOpenForUpsells: [1, 2, 3, 4],
    canRetrieveLiveTrackingStatus: [3, 4],
    canPollQueries: [1, 2, 3, 4],
  },
});

type PotentialActions = PotentialActionItem<
  PickupPotentialAction | DeliveryPotentialAction | CustomerPotentialAction
>[];

interface Context {
  openForUpsells: TransportRequestOpenForUpsells | null | undefined;
  status: Status | undefined;
  tr: TransportRequest | null | undefined;
  trId: string | undefined;
  /**
   * The accepted OR delivered TJAL. Should only be one.
   */
  acceptedTjal: TransportJobAccountLink | undefined;
  /**
   * If the job has some pending TJALs that the customer needs to confirm or revoke.
   */
  pendingTjalList: TransportJobAccountLink[] | undefined;
  liveTracking: LiveTrackingStatusForTransportRequest | null | undefined;
  /**
   * When data is mutated (eg: DTPs are updated) it's a good idea to update the TR.
   */
  refresh: () => void;
  refreshTjalList: () => void;
  contactType: ContactType | undefined;
  pickup: TransportRequestPickup | undefined;
  pickupEta: GeoDateTimePeriod | null | undefined;
  delivery: TransportRequestDelivery | undefined;
  deliveryEta: GeoDateTimePeriod | null | undefined;
  potentialAction: PotentialActions;
  isFlexibleDates: boolean;
}

const initialState: Context = {
  tr: null,
  trId: undefined,
  acceptedTjal: undefined,
  pendingTjalList: [],
  status: undefined,
  refresh: () => ({}),
  refreshTjalList: () => ({}),
  contactType: undefined,
  openForUpsells: null,
  liveTracking: null,
  pickup: undefined,
  pickupEta: null,
  delivery: undefined,
  deliveryEta: null,
  potentialAction: [],
  isFlexibleDates: false,
};

const TransportContext = createContext<Context>(initialState);

export const TransportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslationContext();
  const freshChat = useFreshChat();
  const { id } = useRootParams();

  const isValidUUID = isUUID(id);

  /**
   * Fetch TR for customers
   */
  const tr = useQuery([CacheKey.RETRIEVE_TR, id], () => coreClient.transportRequests.retrieve({ id }), {
    enabled: isValidUUID,
    retry: 1,
  });

  /**
   * Fetch Details for stop contact
   * - When pickup/delivery we use the ID from the params
   * - When customer page we need to load the delivery contact to determine potential actions later
   */
  const stopId = tr.data ? getIdFromIri(tr.data.deliveries?.[0].contact) : id;
  const stop = useQuery(
    [CacheKey.RETRIEVE_LIST_DETAILS, stopId],
    () => coreClient.stops.listDetailsForContact({ id: stopId as string }),
    {
      enabled: isUUID(stopId),
      retry: 1,
    }
  );
  const stopDetails = stop.data?.["hydra:member"];
  const stopData = stopDetails?.[0];
  const stopType = stopData?.["@type"];

  // Determine contact type
  let contactType: ContactType | undefined = undefined;
  if (tr.data) contactType = "customer";
  if (!tr.data && stopType) contactType = stopType.toLowerCase() as ContactType;

  /**
   * Determine potential actions
   * - When pickup/delivery we can read it from stopData
   * - When customer page, we load the stopData for delivery and the customer data and merge the potential actions
   */
  const customerId = getIdFromIri(tr.data?.customer["@id"]);
  const transportCustomer = useQuery(
    [CacheKey.RETRIEVE_CUSTOMER, id],
    () => coreClient.customers.retrieve({ id: customerId as string }),
    {
      enabled: !!customerId && contactType === "customer",
      retry: 1,
    }
  );
  // stopData ==p pickup / delivery
  let potentialAction: PotentialActions = stopData?.potential_action || [];
  // If customer page we concat customer actions in there
  if (contactType === "customer") {
    potentialAction = potentialAction.concat(transportCustomer.data?.potential_action || []);
  }

  /**
   * Fetch TR for Stop (in case of stop contact)
   * NOTE: We must first fetch the item set from the stop to get a ref to the TR ID.
   * NOTE: This is separate query so we can tell if TR data was derived from the stop contact vs customer.
   */
  const itemSetId = getIdFromIri(stopData?.item_sets?.[0]);
  const itemSet = useQuery(
    [CacheKey.RETRIEVE_ITEM_SETS, itemSetId],
    () => coreClient.itemSets.retrieve({ id: itemSetId || "" }),
    {
      enabled: !!itemSetId,
    }
  );

  const itemSetTrId = getIdFromIri(itemSet.data?.transport_request as string | undefined);

  const itemSetTr = useQuery(
    [CacheKey.RETRIEVE_TR],
    () => coreClient.transportRequests.retrieve({ id: itemSetTrId || "" }),
    {
      enabled: !!itemSetTrId,
      retry: 1,
    }
  );

  // If the tr query resolves successfully, then we know that the ID from the URL param is for a TR.
  const reconciledTrId = tr.data ? id : itemSetTrId;

  // Check both TR queries and pick the one that is available
  const reconciledTr = tr.data ? tr : itemSetTr;
  const reconciledTrData = tr.data || itemSetTr.data || null;

  const stops = reconciledTr.data ? getTypedStopsFromTr(reconciledTr.data) : undefined;

  // Derive the preferred language settings (if already provided)
  const currentLocale = i18n.locale;
  let preferredLocale: Locale | undefined | null = undefined;
  if (contactType === "customer") preferredLocale = reconciledTr.data?.customer.preferred_locale;
  if (contactType === "pickup") preferredLocale = stops?.pickup.contact.preferred_locale;
  if (contactType === "delivery") preferredLocale = stops?.delivery.contact.preferred_locale;

  // NOTE: if a contact/customer has a preferred_locale, then make sure the language picker is aligned.
  // See language picker for mutations when a new language is selected.
  useEffect(() => {
    if (preferredLocale) {
      // NOTE: sometimes core still returns locales with an underscore ("_") instead of a dash ("-")
      // therefore must noralize again for good measure.
      const parsedPreferredLocale = localeNormalizer.parseLocale(preferredLocale);
      if (currentLocale !== parsedPreferredLocale) {
        // NOTE: pretty safe to type locale as Locale at this stage.
        i18n.changeLocale?.(parsedPreferredLocale);
      }
    }
  }, [currentLocale, preferredLocale]);

  /**
   * The overall status to show the user.
   * This is a number value and is derived from various sources.
   */
  const status = getStatus(reconciledTrData);

  const { perms } = getPerms(contactType, status);

  /**
   * Pickup ETA
   */
  const pickupId = stops?.pickup?.id;
  const pickupEta = useQuery(
    [CacheKey.RETRIEVE_ETA_PERIOD, pickupId],
    () => routePlannerClient.geo.retrieveEtaPeriodForStop({ stopId: pickupId as string }),
    {
      enabled: Boolean(perms?.canRetrievePickupEta && pickupId),
    }
  );
  useEffect(() => {
    if (!pickupEta.data || !pickupId) return;
    sendEtaEvent(pickupId, pickupEta.data, reconciledTrData?.pickups[0]);
  }, [pickupEta.data]);

  /**
   * Delivery ETA
   */
  const deliveryId = stops?.delivery?.id;
  const deliveryEta = useQuery(
    [CacheKey.RETRIEVE_ETA_PERIOD, deliveryId],
    () => routePlannerClient.geo.retrieveEtaPeriodForStop({ stopId: deliveryId as string }),
    {
      enabled: Boolean(perms?.canRetrieveDeliveryEta && deliveryId),
    }
  );
  useEffect(() => {
    if (!deliveryEta.data || !deliveryId) return;
    sendEtaEvent(deliveryId, deliveryEta.data, reconciledTrData?.deliveries[0]);
  }, [deliveryEta.data]);

  /**
   * Upsells
   */
  const openForUpsells = useQuery(
    [CacheKey.RETRIEVE_OPEN_FOR_UPSELLS, reconciledTrId],
    () => coreClient.transportRequests.retrieveOpenForUpsells({ id: reconciledTrId || "" }),
    {
      enabled: !!reconciledTrId && perms?.canRetrieveOpenForUpsells,
    }
  );

  /**
   * Fresh chat - set user properties depending on whether customer or stop contact
   */
  useEffect(() => {
    // If we are dealing with a customer (ie, TR id)
    if (freshChat && tr.data) {
      const { customer } = tr.data;
      logger.setCustomer(customer);
      freshChat.user.setProperties({
        email: customer.email,
        firstName: customer.first_name,
        lastName: customer.last_name,
        phone: customer.phone,
      });
    }

    // Dealing with a stop contact (ie, pickup/delivery stop contact id)
    if (freshChat && stopData) {
      const contact = stopData.contact as Contact | undefined;
      logger.setCustomer(contact);
      freshChat.user.setProperties({
        firstName: contact?.first_name || "",
        lastName: contact?.last_name || "",
        email: contact?.email || "",
        phone: contact?.phone || "",
      });
    }

    return () => {
      // Destroy the widget when the user leaves the TransportRequest content.
      freshChat?.destroy();
    };
  }, [Boolean(freshChat), Boolean(tr.data), Boolean(stopData)]);

  /**
   * Fetch TJALS
   */
  const tjalList = useQuery(
    [CacheKey.RETRIEVE_TJAL_LIST, reconciledTrId],
    () =>
      coreClient.transportRequests.listTransportJobAccountLinks({
        id: reconciledTrId || "",
        state: ["pending", "temporary_accepted", "accepted", "delivered"],
      }),
    {
      enabled: !!reconciledTrId,
    }
  );
  const tjals = tjalList.data?.["hydra:member"];

  /**
   * Check if some driver has accepted this transport.
   */
  const acceptedTjal = tjals?.find(({ state }) => {
    return ["delivered", "accepted"].includes(state);
  });

  const pendingTjalList = acceptedTjal
    ? undefined
    : tjals?.filter(({ state }) => {
        return !["cancelled_by_driver", "rejected"].includes(state);
      });

  /**
   * Fetch live tracking status
   */
  const { data: liveTracking } = useQuery(
    [CacheKey.RETRIEVE_LIVE_TRACKING_STATUS, reconciledTrId],
    () => coreClient.transportRequests.retrieveLiveTrackingStatus({ id: reconciledTrId || "" }),
    {
      // NOTE: do not fetch live tracking status when the overall job status is cancelled,
      // or submitted, or merely scheduled.
      enabled: !!reconciledTrId && perms?.canRetrieveLiveTrackingStatus,
    }
  );

  const context: Context = {
    liveTracking,
    status,
    acceptedTjal,
    pendingTjalList,
    refresh: () => {
      stop.refetch();
      reconciledTr.refetch();
      transportCustomer.refetch();
    },
    contactType,
    openForUpsells: openForUpsells.data,
    trId: reconciledTrId,
    tr: reconciledTrData,
    refreshTjalList: tjalList.refetch,
    pickup: reconciledTrData?.pickups[0] as TransportRequestPickup | undefined,
    pickupEta: pickupEta.data,
    delivery: reconciledTrData?.deliveries[0] as TransportRequestDelivery | undefined,
    deliveryEta: deliveryEta.data,
    potentialAction,
    isFlexibleDates: isFlexible(reconciledTrData),
  };

  logger.dev("useTransportContext", context);
  logger.setContext("Transport Context", context);

  // We fetch both resources when is customer tracking page, so we should have different uuids.
  if (tr.data && stopData && stopId === getIdFromIri(tr.data)) {
    logger.message("Duplicate UUID for TR and stopContact");
    return <Redirect to={Routes.NOT_FOUND} />;
  }

  // If invalid UUID, redirect.
  if (!isValidUUID) {
    return <Redirect to={Routes.NOT_FOUND} />;
  }

  // If both the TR query AND the Stop Contact query are done loading...
  // ...and no data was returned for either, then the ID is bad.
  if (!tr.isLoading && !tr.data && !stop.isLoading && !stopData) {
    return <Redirect to={Routes.NOT_FOUND} />;
  }

  return <TransportContext.Provider value={context}>{children}</TransportContext.Provider>;
};

export const useTransportContext = (): Context => {
  return useContext(TransportContext);
};

const sendEtaEvent = (
  stopId: string,
  eta: GeoDateTimePeriod,
  stop: TransportRequestDelivery | TransportRequestPickup | undefined
): void => {
  // First filter out the accepted commitment
  const stopCommitment = stop?.commitments.find((commitment) => commitment.accepted);
  if (!stopCommitment) return;

  // We can only send one Available DTP to GA, in theory there could be multiple
  // So we need to find the available DTP that is on the same day as the committed one.
  const availableDtp = stop?.available_datetime_periods.find((dtp) =>
    isSameDay(new Date(dtp.start), new Date(stopCommitment.committed_datetime_period.start))
  );
  if (!availableDtp) return;
  track({
    event: "stopEta",
    stopId,
    eta,
    availableDtp,
    commitedDtp: stopCommitment.committed_datetime_period,
  });
};

const isFlexible = (tr: TransportRequest | null): boolean => {
  if (!tr) return false;
  return !!localStorage.getItem("BRENGER_IS_FLEX_DATES") || !!tr.internal_attributes.flexible_dates?.result;
};
