import {
  Address,
  GeoDateTimePeriod,
  DateTimePeriod as IDateTimePeriod,
  TransportRequestDelivery,
  TransportRequestPickup,
} from "@brenger/api-client";
import cn from "classnames";
import React from "react";

import { ExtraSmall, IconID, IconInfoCircle } from "@brenger/react";
import { getAbbrvForAdminArea, isNowBeforeDateTimePeriod } from "@brenger/utils";
import { ColumnContainer, IconCalendar, IconTruck, PartnerLogo, TimePeriod } from "../../components";
import { useFormatDate, useTranslationContext, useTransportContext } from "../../hooks";
import { getShowAppointmentDetails } from "../../utils/getShowAppointmentDetails";

interface Props {
  stop: TransportRequestPickup | TransportRequestDelivery;
  stopType: "pickup" | "delivery";
  /**
   * [Available] // DTPs
   */
  available: IDateTimePeriod[];
  /**
   * Single Committed or ETA - DTP
   */
  expected: IDateTimePeriod | GeoDateTimePeriod | null;
  isFlexibleDates: boolean;
}

const AppointmentSummaryStop: React.FC<Props> = ({ stop, stopType, available, expected, isFlexibleDates }) => {
  const { t } = useTranslationContext();
  const formatDate = useFormatDate("weekday-month-long");
  const formatDateShort = useFormatDate("day-month-short");
  const a = stop.address as Address | undefined;
  const addressAsString = [
    a?.locality,
    a?.administrative_area ? getAbbrvForAdminArea(a.administrative_area) : undefined,
    a?.country_code,
  ]
    .filter(Boolean)
    .join(", ");
  const hasExpected = !!expected;
  // Try to filter available DTPs that are in the future. No longer relevant.
  const availableDTPsInFuture = available.filter(isNowBeforeDateTimePeriod);
  // Prefer to display only future available DTPs, else fall back to whatever we have.
  const availableDTPs = availableDTPsInFuture.length ? availableDTPsInFuture : available;
  return (
    <>
      <div className={cn("flex", "gap-x-4")}>
        <div className={cn("pt-1", "h6", "text-blue-600")}>
          {!hasExpected && <IconCalendar />}
          {hasExpected && <IconTruck />}
        </div>
        <div>
          {availableDTPs.length !== 0 && (
            <>
              <h6 className={cn("text-blue-600")}>
                {!hasExpected && t((d) => d.appointment_summary[`${stopType}_appointment`])}
                {hasExpected && t((d) => d.appointment_summary[`expected_${stopType}_time`])}
              </h6>
              <div className={cn("br-text-lg", "pt-1", "pb-[.125rem]")}>
                {/* In case of expected we only want to show one of them and the last one in array is the most accurate. Example: [Commited, Committed?, ETA?] */}
                {hasExpected && (
                  <div className={cn("md:flex", "gap-x-3")}>
                    <div className={cn("first-letter:uppercase")}>{formatDate(expected.start)}</div>
                    <TimePeriod className={"italic"} dtp={expected} />
                  </div>
                )}
                {/* If we don't have any expected DTPs yet, then show all available ones */}
                {!hasExpected &&
                  !isFlexibleDates &&
                  availableDTPs.map((dtp, i) => {
                    return (
                      <div key={i} className={cn("md:flex", "gap-x-3", "pb-[.125rem]")}>
                        <div className={cn("first-letter:uppercase")}>{formatDate(dtp.start)}</div>
                        <TimePeriod dtp={dtp} />
                      </div>
                    );
                  })}
                {!hasExpected && isFlexibleDates && (
                  <div className={cn("md:flex", "gap-x-3", "pb-[.125rem]")}>
                    {t((d, template) =>
                      template(d.flex_dates.display_date_range, {
                        first_date: formatDateShort(availableDTPs[0].start),
                        last_date: formatDateShort(availableDTPs[availableDTPs.length - 1].start),
                      })
                    )}
                    <TimePeriod dtp={availableDTPs[0]} />
                  </div>
                )}
              </div>
            </>
          )}
          <div className={cn("text-gray-600")}>{addressAsString}</div>
        </div>
      </div>
      {hasExpected && (
        <div className={cn("flex", "gap-x-2", "pt-4", "text-blue-600")}>
          <div className={cn("pt-1")}>
            <ExtraSmall>
              <IconInfoCircle />
            </ExtraSmall>
          </div>
          <ExtraSmall
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              __html: t((d, template) =>
                template(d.appointment_summary.info_expected, {
                  appointment: `<a class="font-bold underline" href="#${stopType}-appointment-details">${t(
                    (dict) => dict.appointment_summary[`${stopType}_appointment`]
                  ).toLowerCase()}</a>`,
                  expected_appointment_time: t(
                    (dict) => dict.appointment_summary[`expected_${stopType}_time`]
                  ).toLowerCase(),
                })
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export const AppointmentSummary: React.FC = () => {
  const ctx = useTransportContext();

  const { pickup, delivery } = ctx;

  if (!pickup || !delivery || !ctx.tr) return null;

  // Get first pickup commitment
  const commitedPickupDtp = pickup?.commitments.find((commitment) => commitment.accepted);
  // Setup array with Expected Pickup DTPs
  const pickupExpected = [commitedPickupDtp?.committed_datetime_period, ctx.pickupEta].filter(
    // Make sure that we are only passing valid DTPs. So no null or .start | .end === null.
    (dtp) => dtp?.start && dtp.end
  ) as (IDateTimePeriod | GeoDateTimePeriod)[];

  // Get first delivery commitment
  const commitedDeliveryDtp = delivery.commitments.find((commitment) => commitment.accepted);
  // Setup array with Expected Delivery DTPs
  const deliveryExpected = [commitedDeliveryDtp?.committed_datetime_period, ctx.deliveryEta].filter(
    // Make sure that we are only passing valid DTPs. So no null or .start | .end === null.
    (dtp) => dtp?.start && dtp.end
  ) as (IDateTimePeriod | GeoDateTimePeriod)[];

  const { showPickup, showDelivery, showPickupSummary, showDeliverySummary } = getShowAppointmentDetails({
    type: ctx.contactType,
    source: ctx.tr.source_flow,
  });

  return (
    <div className={cn("xl:flex", "xl:gap-x-40", "xl:container", "xl:mx-auto", "xl:px-4")}>
      <ColumnContainer withDivider={true}>
        <div className={cn("flex", "gap-x-4", "text-blue-600")}>
          <div className={cn("pt-1", "h6")}>
            <IconID />
          </div>
          <h6>{ctx.tr?.short_id}</h6>
        </div>
        <PartnerLogo source={ctx.tr?.source_flow} className="mt-4" />
      </ColumnContainer>
      {showPickupSummary && (
        <ColumnContainer withDivider={ctx.contactType === "customer"} className={"xl:min-w-[22rem]"}>
          <AppointmentSummaryStop
            stop={pickup}
            stopType={"pickup"}
            available={pickup.available_datetime_periods}
            // Always pass the last one, which is the latest or most accurate, example [commited, commited, eta]
            expected={showPickup && pickupExpected.length ? pickupExpected[pickupExpected.length - 1] : null}
            isFlexibleDates={ctx.isFlexibleDates}
          />
        </ColumnContainer>
      )}
      {showDeliverySummary && (
        <ColumnContainer withDivider={false} className={"xl:min-w-[22rem]"}>
          <AppointmentSummaryStop
            stop={delivery}
            stopType={"delivery"}
            available={delivery.available_datetime_periods}
            // Always pass the last one, which is the latest or most accurate, example [commited, commited, eta]
            expected={showDelivery && deliveryExpected.length ? deliveryExpected[deliveryExpected.length - 1] : null}
            isFlexibleDates={ctx.isFlexibleDates}
          />
        </ColumnContainer>
      )}
    </div>
  );
};
